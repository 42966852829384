import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
import customRoutes from '../../customApp/router';

const routes = [
  {
    role: '',
    type: '',
    path: '',
    component: asyncComponent(() => import('../Widgets/index.js')),
  },
  {
    role: '',
    type: '',
    path: 'list/agences',
    component: asyncComponent(() => import('../views/settingsLogistics/agences/indexAgences')),
  },
  {
    role: '',
    type: '',
    path: 'list/zones',
    component: asyncComponent(() => import('../views/settingsLogistics/zones/indexZones')),
  },
  {
    role: '',
    type: '',
    path: 'list/permissions',
    component: asyncComponent(() => import('../views/settingsLogistics/permissions/indexPemissions')),
  },
  {
    role: '',
    type: '',
    path: 'list/privileges',
    component: asyncComponent(() => import('../views/settingsLogistics/privileges/indexPrivileges')),
  },
  {
    role: '',
    type: '',
    path: 'list/articles',
    component: asyncComponent(() => import('../views/settingsLogistics/articles/indexArticles')),
  },
  {
    role: '',
    type: '',
    path: 'list/anomalies',
    component: asyncComponent(() => import('../views/settingsLogistics/anomalies/indexAnomalies')),
  },
  {
    role: '',
    type: '',
    path: 'list/vehicules',
    component: asyncComponent(() => import('../views/settingsLogistics/vehicules/indexVehicules')),
  },
  {
    role: '',
    type: '',
    path: 'list/drivers',
    component: asyncComponent(() => import('../views/settingsLogistics/drivers/indexDrivers')),
  },
  {
    role: '',
    type: '',
    path: 'list/providers',
    component: asyncComponent(() => import('../views/settingsLogistics/providers/indexProviders')),
  },
  {
    role: '',
    type: '',
    path: 'list/admins',
    component: asyncComponent(() => import('../views/settingsLogistics/admins/indexAdmins')),
  },
  {
    role: '',
    type: '',
    path: 'list/storekeepers',
    component: asyncComponent(() => import('../views/settingsLogistics/storekeeprs/indexStorekeeprs')),
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    role: '',
    type: '',
    path: 'services/categories',
    component: asyncComponent(() => import('../views/settingsServices/categories/indexCategories')),
  },

  {
    role: '',
    type: '',
    path: 'services/customers',
    component: asyncComponent(() => import('../views/settingsServices/customers/indexCustomers')),
  },
  {
    role: '',
    type: '',
    path: 'services/workers',
    component: asyncComponent(() => import('../views/settingsServices/workers/indexWorkers')),
  },
  {
    role: '',
    type: '',
    path: 'services/currencies',
    component: asyncComponent(() => import('../views/settingsServices/currencies/indexCurrencies')),
  },
  {
    role: '',
    type: '',
    path: 'services/products',
    component: asyncComponent(() => import('../views/settingsServices/products/indexProducts')),
  },
  

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  {
    role: '',
    type: '',
    path: 'orders',
    component: asyncComponent(() => import('../views/ordersList/indexOrders')),
  },
  {
    role: '',
    type: '',
    path: 'orders/status',
    component: asyncComponent(() => import('../views/ordersList/Order.status')),
  },
  {
    role: '',
    type: '',
    path: 'orders/customers',
    component: asyncComponent(() => import('../views/ordersList/Order.customers')),
  },
  {
    role: '',
    type: '',
    path: 'orders/workers',
    component: asyncComponent(() => import('../views/ordersList/Orders.workers')),
  },
  {
    role: '',
    type: '',
    path: 'orders/prefacture',
    component: asyncComponent(() => import('../views/ordersList/Orders.prefacture')),
  },


  
  





  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    role: '',
    type: '',
    path: 'inbox',
    component: asyncComponent(() => import('../Mail')),
  },
  {
    role: '',
    type: '',
    path: 'mailbox',
    component: asyncComponent(() => import('../Mail')),
  },
  {
    role: '',
    type: '',
    path: 'calendar',
    component: asyncComponent(() => import('../Calendar/Calendar')),
  },
  {
    role: '',
    type: '',
    path: 'googlemap',
    component: asyncComponent(() => import('../Map/GoogleMap/googleMap')),
  },
  {
    role: '',
    type: '',
    path: 'leafletmap',
    component: asyncComponent(() => import('../Map/Leaflet/leaflet')),
  },
  {
    role: '',
    type: '',
    path: 'table_ant',
    component: asyncComponent(() => import('../Tables/antTables')),
  },
  {
    role: '',
    type: '',
    path: 'allFormComponent',
    component: asyncComponent(() => import('../Forms/allComponents/')),
  },
  {
    role: '',
    type: '',
    path: 'InputField',
    component: asyncComponent(() => import('../Forms/Input')),
  },
  {
    role: '',
    type: '',
    path: 'editor',
    component: asyncComponent(() => import('../Forms/editor/')),
  },
  {
    role: '',
    type: '',
    path: 'stepperForms',
    component: asyncComponent(() => import('../Forms/StepperForms/')),
  },
  {
    role: '',
    type: '',
    path: 'FormsWithValidation',
    component: asyncComponent(() => import('../Forms/FormsWithValidation')),
  },
  {
    role: '',
    type: '',
    path: 'progress',
    component: asyncComponent(() => import('../Forms/Progress')),
  },
  {
    role: '',
    type: '',
    path: 'button',
    component: asyncComponent(() => import('../Forms/Button')),
  },
  {
    role: '',
    type: '',
    path: 'tab',
    component: asyncComponent(() => import('../Forms/Tab')),
  },
  {
    role: '',
    type: '',
    path: 'autocomplete',
    component: asyncComponent(() => import('../Forms/AutoComplete')),
  },
  {
    role: '',
    type: '',
    path: 'checkbox',
    component: asyncComponent(() => import('../Forms/Checkbox')),
  },
  {
    role: '',
    type: '',
    path: 'radiobox',
    component: asyncComponent(() => import('../Forms/Radiobox/')),
  },
  {
    role: '',
    type: '',
    path: 'selectbox',
    component: asyncComponent(() => import('../Forms/Select/')),
  },
  {
    role: '',
    type: '',
    path: 'transfer',
    component: asyncComponent(() => import('../Forms/Transfer/')),
  },
  {
    role: '',
    type: '',
    path: 'gridLayout',
    component: asyncComponent(() => import('../Box/GridLayout')),
  },
  {
    role: '',
    type: '',
    path: 'notes',
    component: asyncComponent(() => import('../Notes')),
  },
  {
    role: '',
    type: '',
    path: 'todo',
    component: asyncComponent(() => import('../Todo')),
  },
  {
    role: '',
    type: '',
    path: 'articles',
    component: asyncComponent(() => import('../FirestoreCRUD/Article')),
  },
  {
    role: '',
    type: '',
    path: 'investors',
    component: asyncComponent(() => import('../FirestoreCRUD/Investor')),
  },
  {
    role: '',
    type: '',
    path: 'contacts',
    component: asyncComponent(() => import('../Contacts')),
  },
  {
    role: '',
    type: '',
    path: 'alert',
    component: asyncComponent(() => import('../Feedback/Alert')),
  },
  {
    role: '',
    type: '',
    path: 'modal',
    component: asyncComponent(() => import('../Feedback/Modal/')),
  },
  {
    role: '',
    type: '',
    path: 'message',
    component: asyncComponent(() => import('../Feedback/Message')),
  },
  {
    role: '',
    type: '',
    path: 'notification',
    component: asyncComponent(() => import('../Feedback/Notification')),
  },
  {
    role: '',
    type: '',
    path: 'Popconfirm',
    component: asyncComponent(() => import('../Feedback/Popconfirm')),
  },
  {
    role: '',
    type: '',
    path: 'spin',
    component: asyncComponent(() => import('../Feedback/Spin')),
  },
  {
    role: '',
    type: '',
    path: 'shuffle',
    component: asyncComponent(() => import('../Shuffle')),
  },
  {
    role: '',
    type: '',
    path: 'affix',
    component: asyncComponent(() => import('../Navigation/affix')),
  },
  {
    role: '',
    type: '',
    path: 'breadcrumb',
    component: asyncComponent(() =>
      import('../Uielements/Breadcrumb/breadcrumb')
    ),
  },
  {
    role: '',
    type: '',
    path: 'backToTop',
    component: asyncComponent(() => import('../Navigation/backToTop')),
  },
  {
    role: '',
    type: '',
    path: 'dropdown',
    component: asyncComponent(() => import('../Uielements/Dropdown/dropdown')),
  },
  {
    role: '',
    type: '',
    path: 'op_badge',
    component: asyncComponent(() => import('../Uielements/Badge')),
  },
  {
    role: '',
    type: '',
    path: 'op_card',
    component: asyncComponent(() => import('../Uielements/Card')),
  },
  {
    role: '',
    type: '',
    path: 'op_carousel',
    component: asyncComponent(() => import('../Uielements/Carousel')),
  },
  {
    role: '',
    type: '',
    path: 'op_collapse',
    component: asyncComponent(() => import('../Uielements/Collapse')),
  },
  {
    role: '',
    type: '',
    path: 'op_tooltip',
    component: asyncComponent(() => import('../Uielements/Tooltip/')),
  },
  {
    role: '',
    type: '',
    path: 'rating',
    component: asyncComponent(() => import('../Uielements/rating/')),
  },
  {
    role: '',
    type: '',
    path: 'tree',
    component: asyncComponent(() => import('../Uielements/Tree/')),
  },
  {
    role: '',
    type: '',
    path: 'op_tag',
    component: asyncComponent(() => import('../Uielements/Tag')),
  },
  {
    role: '',
    type: '',
    path: 'op_timeline',
    component: asyncComponent(() => import('../Uielements/Timeline')),
  },
  {
    role: '',
    type: '',
    path: 'op_popover',
    component: asyncComponent(() => import('../Uielements/Popover')),
  },
  {
    role: '',
    type: '',
    path: 'googleChart',
    component: asyncComponent(() => import('../Charts/googleChart')),
  },
  {
    role: '',
    type: '',
    path: 'reecharts',
    component: asyncComponent(() => import('../Charts/recharts')),
  },
  {
    role: '',
    type: '',
    path: 'menu',
    component: asyncComponent(() => import('../Navigation/menu')),
  },
  {
    role: '',
    type: '',
    path: 'ReactChart2',
    component: asyncComponent(() => import('../Charts/reactChart2')),
  },
  {
    role: '',
    type: '',
    path: 'pagination',
    component: asyncComponent(() =>
      import('../Uielements/Pagination/pagination')
    ),
  },
  {
    role: '',
    type: '',
    path: 'card',
    component: asyncComponent(() => import('../Ecommerce/card')),
  },
  {
    role: '',
    type: '',
    path: 'cart',
    component: asyncComponent(() => import('../Ecommerce/cart')),
  },
  {
    role: '',
    type: '',
    path: 'checkout',
    component: asyncComponent(() => import('../Ecommerce/checkout')),
  },
  {
    role: '',
    type: '',
    path: 'shop',
    component: asyncComponent(() =>
      import('../Ecommerce/algolia/instantSearch')
    ),
  },
  {
    role: '',
    type: '',
    path: 'reactDates',
    component: asyncComponent(() =>
      import('../AdvancedUI/ReactDates/reactDates')
    ),
  },
  {
    role: '',
    type: '',
    path: 'codeMirror',
    component: asyncComponent(() => import('../AdvancedUI/codeMirror')),
  },
  {
    role: '',
    type: '',
    path: 'uppy',
    component: asyncComponent(() => import('../AdvancedUI/uppy')),
  },
  {
    role: '',
    type: '',
    path: 'dropzone',
    component: asyncComponent(() => import('../AdvancedUI/dropzone')),
  },
  {
    role: '',
    type: '',
    path: 'youtubeSearch',
    component: asyncComponent(() => import('../YoutubeSearch')),
  },
  {
    role: '',
    type: '',
    path: 'frappeChart',
    component: asyncComponent(() => import('../Charts/frappeChart')),
  },
  {
    role: '',
    type: '',
    path: 'invoice/:invoiceId',
    component: asyncComponent(() => import('../Invoice/singleInvoice')),
  },
  {
    role: '',
    type: '',
    path: 'invoice',
    component: asyncComponent(() => import('../Invoice')),
  },
  {
    role: '',
    type: '',
    path: 'chat',
    component: asyncComponent(() => import('../Chat')),
  },
  ...customRoutes,
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
